import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [showOverlay, setShowOverlay] = useState(true);
  const [copied, setCopied] = useState(false);
  const [bricks, setBricks] = useState(() => {
    const savedBricks = localStorage.getItem('bricks');
    return savedBricks ? JSON.parse(savedBricks) : [];
  });
  const [brickName, setBrickName] = useState('');

  const contractAddress = "2RP4Rk44vWBdMmi3fzrrPgy4CW1WNBFwJBfymk8hs3BJ";
  const shortenedAddress = `${contractAddress.slice(0, 6)}...${contractAddress.slice(-6)}`;

  const handleCopy = (e) => {
    navigator.clipboard.writeText(contractAddress);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleAddBrick = () => {
    if (brickName.trim() !== '') {
      const newBricks = [...bricks, brickName]; 
      setBricks(newBricks);
      setBrickName('');
      localStorage.setItem('bricks', JSON.stringify(newBricks));
    }
  };

  useEffect(() => {
    const playAudio = () => {
      const audio = document.getElementById('background-audio');
      audio.play();
      document.removeEventListener('click', playAudio);
    };

    document.addEventListener('click', playAudio);

    return () => {
      document.removeEventListener('click', playAudio);
    };
  }, []);

  return (
    <div className="App">
      <audio id="background-audio" src={`${process.env.PUBLIC_URL}/audio.mp3`} />
      <header className="App-header">
        <div className="header-content">
          <img src={`${process.env.PUBLIC_URL}/HOD.png`} className="HOD-image" alt="HOD Imagery" />
          <div className="logo-and-title">
            <h1> <img src={`${process.env.PUBLIC_URL}/HOD_LOGO2.png`} alt="HOD Logo" /></h1>
            
            <p>Bricks are the future of Web3, so buy this shit.</p>
            <p>CA:</p>
            <p className="contract-address" onClick={handleCopy}>
              {shortenedAddress}
              {copied && <span className="copied-message">Copied!</span>}
            </p>
            
            <div className="buttons-container">
              <div className="buttons">
                <img src={`${process.env.PUBLIC_URL}/HOD_DEXSCREENER.png`} alt="HOD Dexscreener" />
                <a href="https://pump.fun/2RP4Rk44vWBdMmi3fzrrPgy4CW1WNBFwJBfymk8hs3BJ" target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/HOD_PUMPFUN.png`} alt="HOD Pumpfun" />
                </a>
                <a href="https://t.me/HODonSolPort" target="_blank" rel="noopener noreferrer">
                <img src={`${process.env.PUBLIC_URL}/HOD_TELEGRAM.png`} alt="HOD Telegram" />
                </a>
                <a href="https://x.com/HODOnSol" target="_blank" rel="noopener noreferrer">
  <img src={`${process.env.PUBLIC_URL}/HOD_X.png`} alt="HOD X" />
</a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <div className="iframe-container">
  <div className="iframe-overlay">
    <iframe
      className="limited-iframe"
      src="https://pump.fun/2RP4Rk44vWBdMmi3fzrrPgy4CW1WNBFwJBfymk8hs3BJ"
      allowFullScreen
      title="PumpFun"
    ></iframe>
  </div>
</div> */}
      {/* <div className="brick-game">
        <h2>Lay a Brick</h2>
        <input
          type="text"
          value={brickName}
          onChange={(e) => setBrickName(e.target.value)}
          placeholder="Set your Name on a Brick"
          maxLength="44"
        />
        <button onClick={handleAddBrick}>Add Brick</button>
        <div className="brick-container">
          {bricks.map((brick, index) => (
            <div key={index} className="brick">
              {brick}
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
}

export default App;